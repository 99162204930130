<template>
  <div
    style="position: relative; height: calc(100vh - 50px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <div>
        <el-input placeholder="请输入订单号" v-model="orderId"></el-input>
        <el-select
          v-model="city"
          placeholder="请选择城市"
          @change="handleSelectPlay"
        >
          <el-option
            v-for="item in optionsCity"
            :key="item.id"
            :label="item.country + '-' + item.city"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select v-model="playId" placeholder="请选择游玩项目">
          <el-option
            v-for="item in optionsPlay"
            :key="item.sightseeingId"
            :label="item.goodsName"
            :value="item.sightseeingId"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border>
        <el-table-column label="商品主图" align="center" width="200">
          <template slot-scope="scope">
            <el-image
              style="height: 120px"
              :src="scope.row.sightseeingDTO.imageList[0]"
              :preview-src-list="scope.row.sightseeingDTO.imageList"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="sightseeingDTO.goodsName"
          label="商品名称"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrderExt.playTime"
          label="游玩日期"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column label="规格" align="center" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.bizOrderExt.skuAttr }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="bizOrderExt.singlePrice"
          label="单价"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrderExt.goodsNum"
          label="数量"
          align="center"
        >
        </el-table-column>
        <el-table-column label="实收款" align="center" width="160">
          <template slot-scope="{ row }">
            <p v-if="row.payOrder.payType == 1">${{ row.payOrder.amount }}</p>
            <p v-else-if="row.payOrder.payType == 10">
              {{ row.payOrder.p95Amount }}<br />
              CPLE积分抵扣：-${{ row.payOrder.p05Amount }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="payOrder.orderId"
          label="订单号"
          align="center"
          width="140"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrder.createTime"
          label="下单时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrder.orderStatus"
          label="订单状态"
          width="170"
          align="center"
        >
          <template slot-scope="{ row }">
            <div>
              {{ row.bizOrder.orderStatus }}
            </div>
            <el-button size="mini" type="text" @click="handleOrderDetail(row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import { format_time_date, format_time_date_noTime } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "游玩项目",
        },
        {
          name: "订单管理",
        },
      ], // 面包屑数据

      tableData: [],
      orderId: "",
      optionsCity: [],
      optionsPlay: [],
      city: "",
      playId: "",
      date: "",
      data1: {},

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.worldPlayGetOrderList,
      method: "POST",
      params: JSON.stringify({}),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          res.data.data.list.map((item) => {
            item.bizOrderExt.checkInTime = format_time_date_noTime(
              item.bizOrderExt.checkInTime
            );
            item.bizOrderExt.checkOutTime = format_time_date_noTime(
              item.bizOrderExt.checkOutTime
            );
            item.bizOrder.createTime = format_time_date(
              item.bizOrder.createTime
            );
            item.bizOrderExt.playTime = format_time_date_noTime(
              item.bizOrderExt.playTime
            );

            if (item.bizOrder.orderStatus == 1) {
              item.bizOrder.orderStatus = "未支付";
            } else if (item.bizOrder.orderStatus == 2) {
              item.bizOrder.orderStatus = "支付审核中";
            } else if (item.bizOrder.orderStatus == 3) {
              item.bizOrder.orderStatus = "支付审核通过，已支付";
            } else if (item.bizOrder.orderStatus == 4) {
              item.bizOrder.orderStatus = "支付审核拒绝";
            } else if (item.bizOrder.orderStatus == 5) {
              item.bizOrder.orderStatus = "用户取消";
            } else if (item.bizOrder.orderStatus == 6) {
              item.bizOrder.orderStatus = "平台取消";
            } else if (item.bizOrder.orderStatus == 7) {
              item.bizOrder.orderStatus = "已确认";
            }

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);

    const optCity = {
      url: reqUrl.worldTravelSelectCity,
      method: "POST",
      params: JSON.stringify({
        city: "",
        country: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsCity = res.data.data.list;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(optCity);

    const optPlay = {
      url: reqUrl.worldGetSightseeingListOption,
      method: "POST",
      params: JSON.stringify({
        cityId: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsPlay = res.data.data;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(optPlay);
  },

  methods: {
    handleOrderDetail(row) {
      this.$router.push("/worldPlayOrderDetail");
      localStorage.setItem("playRowDetailData", JSON.stringify(row));
    },

    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.worldPlayGetOrderList,
        method: "POST",
        params: JSON.stringify({
          cityId: this.city,
          orderId: this.orderId,
          startDate: this.date[0],
          endDate: this.date[1],
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            res.data.data.list.map((item) => {
              item.bizOrderExt.checkInTime = format_time_date_noTime(
                item.bizOrderExt.checkInTime
              );
              item.bizOrderExt.checkOutTime = format_time_date_noTime(
                item.bizOrderExt.checkOutTime
              );
              item.bizOrder.createTime = format_time_date(
                item.bizOrder.createTime
              );
              item.bizOrderExt.playTime = format_time_date_noTime(
                item.bizOrderExt.playTime
              );

              if (item.bizOrder.orderStatus == 1) {
                item.bizOrder.orderStatus = "未支付";
              } else if (item.bizOrder.orderStatus == 2) {
                item.bizOrder.orderStatus = "支付审核中";
              } else if (item.bizOrder.orderStatus == 3) {
                item.bizOrder.orderStatus = "支付审核通过，已支付";
              } else if (item.bizOrder.orderStatus == 4) {
                item.bizOrder.orderStatus = "支付审核拒绝";
              } else if (item.bizOrder.orderStatus == 5) {
                item.bizOrder.orderStatus = "用户取消";
              } else if (item.bizOrder.orderStatus == 6) {
                item.bizOrder.orderStatus = "平台取消";
              } else if (item.bizOrder.orderStatus == 7) {
                item.bizOrder.orderStatus = "已确认";
              }

              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handleSelectPlay() {
      const optPlay = {
        url: reqUrl.worldGetSightseeingListOption,
        method: "POST",
        params: JSON.stringify({
          cityId: this.city,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            this.optionsPlay = res.data.data;
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
        },
      };
      request(optPlay);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.worldPlayGetOrderList,
        method: "POST",
        params: JSON.stringify({
          cityId: this.city,
          orderId: this.orderId,
          startDate: this.date[0],
          endDate: this.date[1],
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            res.data.data.list.map((item) => {
              item.bizOrderExt.checkInTime = format_time_date_noTime(
                item.bizOrderExt.checkInTime
              );
              item.bizOrderExt.checkOutTime = format_time_date_noTime(
                item.bizOrderExt.checkOutTime
              );
              item.bizOrder.createTime = format_time_date(
                item.bizOrder.createTime
              );
              item.bizOrderExt.playTime = format_time_date_noTime(
                item.bizOrderExt.playTime
              );

              if (item.bizOrder.orderStatus == 1) {
                item.bizOrder.orderStatus = "未支付";
              } else if (item.bizOrder.orderStatus == 2) {
                item.bizOrder.orderStatus = "支付审核中";
              } else if (item.bizOrder.orderStatus == 3) {
                item.bizOrder.orderStatus = "支付审核通过，已支付";
              } else if (item.bizOrder.orderStatus == 4) {
                item.bizOrder.orderStatus = "支付审核拒绝";
              } else if (item.bizOrder.orderStatus == 5) {
                item.bizOrder.orderStatus = "用户取消";
              } else if (item.bizOrder.orderStatus == 6) {
                item.bizOrder.orderStatus = "平台取消";
              } else if (item.bizOrder.orderStatus == 7) {
                item.bizOrder.orderStatus = "已确认";
              }

              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              this.currentPage = data.pageIndex;
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    // 刷新
    refresh() {
      this.reload();
    },

    reset() {
      this.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../index.scss";
</style>
